@import "globals.scss";
.ob-summary {
  border-radius: 20px;
  padding: 24px;
  box-shadow: -2px 2px 20px #0000001a;

  .MuiCardMedia-root {
    .ob-image {
      width: 9rem;
      height: 9rem;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1.25rem auto;

      img {
        object-fit: cover;
        max-height: 100%;
      }
    }
  }

  .MuiCardContent-root {
    padding: 1.25rem 3rem;
  }
}
