@import "globals.scss";
.event-end {
  background-size: 50px 50px;
  margin-top: 50px;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  padding: 5px 0;
  border-radius: 5px;

  &.dark {
    background: repeating-linear-gradient(
      55deg,
      #36394c,
      #36394c 20px,
      #3a3c4f 20px,
      #3a3c4f 41px
    );
    color: #ffffffb3; // 0.7 opacity
  }

  &.light {
    background: repeating-linear-gradient(
      55deg,
      #f1f1f1,
      #f1f1f1 20px,
      #f8f8f8 20px,
      #f8f8f8 41px
    );
    color: var(--TextDark);
  }

  span {
    margin-left: 25px;
    margin-right: 80px;
  }
}
