@import "globals.scss";
.agenda-item-public-ctn {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  overflow: hidden;
  min-height: 140px;
  width: 100%;
  box-sizing: border-box;

  .agenda-item-left {
    padding: 25px;
    min-width: 70px;
    max-width: 70px;
    flex-direction: column;

    .start-time {
      font-size: 16px;
      line-height: 21px;
      color: var(--TextDark);
      font-weight: 500;
    }

    .end-time {
      font-size: 12px;
      line-height: 16px;
      color: var(--TextDark);
      opacity: 0.7;
    }

    .duration {
      font-size: 14px;
      line-height: 19px;
      color: var(--TextDark);
      margin-top: 33px;
      display: flex;
      align-items: center;

      svg {
        width: 17px;
        margin-right: 5px;
        opacity: 0.7;
      }
    }
  }

  .separator {
    width: 2px;
    min-width: 2px;
    max-width: 2px;
    margin: 0;
  }

  .agenda-item-right {
    padding: 25px;
    display: flex;
    max-width: 405px;
    overflow: hidden;
    flex-direction: column;
    box-sizing: border-box;

    h3 {
      margin: 0;
      font-size: 16px;
      line-height: 21px;
      color: var(--TextDark);
      font-weight: 500;
    }

    .item-text {
      font-size: 14px;
      line-height: 21px;
      color: var(--TextDark);
      opacity: 0.7;
      margin-top: 15px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      -webkit-box-orient: vertical;

      &.more {
        display: block;
      }
    }

    .see-more {
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;
      color: var(--GreenRemo);
      margin-left: auto;
      font-size: 14px;
      line-height: 19px;
      margin-top: 10px;

      &:focus {
        outline: none;
      }
    }
  }
}
