@import "globals.scss";
.attendee-stats-ctn {
  .MuiBox-root {
    margin-right: 3px;
    font-size: 14px;

    &.value {
      min-width: 50px;
      font-weight: bold;
    }
  }
}
