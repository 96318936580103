@import "globals.scss";
.agenda.me-content {
  .event-agenda {
    .ea-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    }

    .info-block {
      color: var(--TextDark);
    }

    .ea-cnt {
      position: relative;
      padding-top: 2.125rem;
      min-height: 10rem;
    }

    #event-agenda-add {
      padding: 12px 24px;
      margin-top: 30px;
      display: flex;
      margin-left: auto;
      margin-right: auto;

      svg {
        height: 18px;
        margin-right: 7px;
      }
    }

    .eva-item {
      padding: 0;
      position: relative;
      margin-bottom: 1.125rem;
      cursor: pointer;

      .agenda-item-public-ctn {
        margin-bottom: 0;
      }

      .eva-actions {
        position: absolute;
        display: flex;
        flex-direction: column;
        left: -34px;
        bottom: 0;
      }

      .icon-btn-with-tooltip {
        padding: 0;
        width: 1.5rem;
        background-color: var(--VeryLightGrey);
        color: var(--TextDark);
        border-radius: 4px;
        margin-top: 1rem;
        transition: opacity 200ms ease-in-out 100ms;
        opacity: 0;

        &.Mui-disabled {
          color: var(--LightGrey);
        }

        svg {
          width: 1.5rem;
          height: 1.5rem;
          fill: currentcolor;
        }
      }

      .eva-delete {
        color: var(--LightRed);
        background-color: var(--VeryLightGrey);
        margin-top: 0;
        position: absolute;
        top: 15px;
        right: 25px;
        transition: opacity 200ms ease-in-out 100ms,
          transform 150ms ease-in-out 100ms;
        border-radius: 4px;
        height: 1.5rem;

        svg {
          width: 1.25rem;
          height: 1.25rem;
        }
      }

      &:hover {
        .icon-btn-with-tooltip {
          opacity: 1;
        }
      }
    }

    .ea-empty {
      img {
        margin-bottom: 1rem;
      }
      color: rgb(72 74 86 / 70%);
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 0.875rem;
      line-height: 1.5rem;
      padding-top: 1.25rem;
    }

    .ea-loading .page-loading-container {
      position: relative;
    }
  }
}
