@import "globals.scss";
.eah-scroller {
  display: flex;
  flex: 1 0 80%;
  max-width: 80%;

  .left,
  .right {
    padding: 0;
    border-radius: 0;
    flex: 1 0 6%;

    &:hover {
      // background-color: transparent;
    }
  }

  .eah-items {
    flex: 1 0 88%;
    max-width: 88%;
    overflow: hidden;

    > div {
      display: flex;
      transition: transform 300ms ease-in-out;

      > div {
        flex: 1 0 33.333%;
        color: var(--LightGrey);
        text-align: center;
        line-height: 2.75rem;
        font-size: 0.875rem;
        margin-right: 10px;
        margin-left: 10px;
        max-width: calc(33.33% - 20px);
        cursor: pointer;

        &:hover,
        &.active {
          background-color: #f2f3f8;
          color: var(--TextDark);
          border-radius: 10px;
        }
      }
    }
  }

  &.darkBlue {
    button {
      color: rgb(255 255 255 / 100%);

      &.Mui-disabled {
        color: rgb(255 255 255 / 70%);
      }
    }

    .eah-items > div > div {
      color: rgb(255 255 255 / 90%) !important;

      &:hover {
        background-color: #484a56 !important;
      }
    }

    .active {
      background-color: #484a56 !important;
    }
  }
}
