@import "globals.scss";
.attendee-list-ctn {
  display: flex;
  flex-direction: column;

  .info-block {
    color: #333;
  }

  .MuiPaper-root {
    box-shadow: none;
    position: relative;
    height: fit-content;
    border: 2px rgb(0 0 0 / 23%) solid;
  }

  .MuiTable-root {
    width: 100%;

    .MuiTableHead-root {
      .MuiTableCell-head {
        z-index: 1;
      }
    }

    .MuiTableCell-root {
      font-size: 12px;
      color: "var(--dark)";
    }

    .MuiTableCell-body {
      font-size: 12px;
      font-weight: 400;
    }

    .MuiSvgIcon-root {
      width: 0.8em;
      height: 0.8em;
    }
  }
}

.hl-dialog {
  ul {
    max-height: 10rem;
    overflow: auto;
    line-height: 1.45rem;
  }
}
