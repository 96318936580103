@import "globals.scss";
.me-leftmenu {
  height: 100%;
  overflow-y: auto;

  &.MuiList-root {
    min-width: 200px;
    border-right: 3px solid #fafafa;
    padding-right: 18px;
    box-sizing: border-box;
  }

  .MuiDivider-root {
    margin-top: 10px;
  }

  .MuiListItem-root {
    opacity: 0.7;
    transition: opacity ease-in-out 100ms;
    border-radius: 10px;

    .MuiTypography-body1 {
      font-size: 1rem;
    }

    &:hover {
      background-color: #f2f3f8;
    }
  }

  .MuiCollapse-container {
    .MuiListItem-root {
      margin-bottom: 0;

      .MuiTypography-body1 {
        font-size: 0.875rem;
      }
    }
  }
}
