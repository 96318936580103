@import "globals.scss";
.duration-input {
  display: inline-flex;
  border: 1px solid rgb(0 0 0 / 23%);
  border-radius: 4px;
  padding: 14px 10px;
  margin-top: 10px;
  align-items: center;

  svg {
    margin-right: 5px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input {
    -moz-appearance: textfield;
    width: 24px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    position: relative;
    top: 1px;

    &:active,
    &:focus,
    & {
      outline: none;
      border: none;
    }
  }
}
