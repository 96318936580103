@import "globals.scss";
.ctl-preview {
  $action-bar-min-height: 5rem;

  .MuiDialog-paper {
    max-width: 75rem;
    width: 75vw;
    height: 75vh;
    overflow: hidden;

    .MuiPaper-root {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 90%;
      width: 100%;
    }

    img {
      width: 90%;
      height: 90%;
      object-fit: cover;
    }

    .ctl-left,
    .ctl-right {
      position: absolute;
      z-index: 1;
      transform: translate(0%, -50%);
      left: 2rem;
      top: calc(50% - 45px);
      background-color: var(--BrightGreen);
      color: #fff;

      &:hover {
        background-color: var(--Green);
      }
    }

    .ctl-right {
      transform: translate(0%, -50%);
      right: 2rem;
      left: auto;
    }

    .MuiDialogActions-root.ctl-actions {
      justify-content: center;
      padding: 0;
      height: 10%;
      min-height: $action-bar-min-height;
    }
  }
}

.ct-notif {
  max-width: 330px;
  padding: 8px;

  img {
    margin-bottom: 2rem;
  }

  h4 {
    max-width: 32rem;
    font-weight: 500;
    font-size: 16px;
    color: #0e1f3c;

    &.header {
      margin: 1.25rem 0;
    }

    &.info {
      font-size: 14px;
      margin: 2rem 0;
      display: flex;
      align-items: center;
    }
  }

  .event-list {
    margin-bottom: 2rem;

    ul {
      margin: 0px;
      text-align: left;
      max-width: 20rem;
    }
  }
}

.customize-theme-wrap {
  margin-top: 3rem;

  .ct-types {
    margin-bottom: 2rem;
    margin-top: 2rem;

    .MuiFormControlLabel-root {
      margin-left: 0;
      border-radius: 8px;
      padding: 0 1rem;
      position: relative;

      .MuiRadio-root {
        position: absolute;
        opacity: 0;
      }

      .MuiFormControlLabel-label {
        line-height: 2.5rem;
      }
    }
  }
}
